html,
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    0deg,
    rgba(2, 15, 40, 1) 0%,
    rgba(9, 32, 121, 1) 35%,
    rgba(20, 120, 200, 1) 100%
  );
  cursor: none;
}

canvas {
  position: absolute;
  height: 100vh;
  width: 100vw;
  image-rendering: crisp-edges;
}

#preloader {
  color: #adf;
  font: 20px sans-serif;
  left: 50vw;
  position: absolute;
  top: 30vh;
  transform: translate(-50%, -50%);
  width: 200px;
  z-index: 1;
}

#preloader > div {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
